import { CONFIG_SITE } from 'constants/index';

const defaultData = {
  hero_banner: {
    name: 'Main Banners',
    title: 'Main Banners',
    content: [
      {
        image: {
          path: 'https://storage.googleapis.com/xooply-assets/hero-banner-1_H0BTm_ca3.jpg',
          seo: {
            alt: 'Xooply your business solution',
            desc: 'Xooply your business solution',
          },
        },
        redirectUrl: '/',
        sortIndex: 1,
      },
      {
        type: 'LARGE',
        image: {
          path: 'https://storage.googleapis.com/xooply-assets/hero-banner-2_tAvJJ3UOm.jpg',
          seo: {
            alt: 'bergabung xooply dan temukan semua kemudahan berbelanja',
            desc: 'bergabung xooply dan temukan semua kemudahan berbelanja',
          },
        },
        redirectUrl: '/',
        sortIndex: 2,
      },
    ],
  },
  recommendation_banner: {
    name: 'recommendation banners',
    title: 'Kebutuhan Bisnis Anda',
    content: [
      {
        image: {
          path: 'https://storage.googleapis.com/xooply-assets/recommendation-1_ylApx6Vbk.jpg',
          seo: {
            alt: 'Alat Tulis Kantor',
            desc: 'Alat Tulis Kantor',
          },
        },
        redirectUrl: '/',
        sortIndex: 1,
      },
      {
        image: {
          path: 'https://storage.googleapis.com/xooply-assets/recommendation-2_Qhf41lWHz.jpg',
          seo: {
            alt: 'Stok Pantry',
            desc: 'Stok Pantry',
          },
        },
        redirectUrl: '/',
        sortIndex: 2,
      },
      {
        image: {
          path: 'https://storage.googleapis.com/xooply-assets/recommendation-3_2O8WRjSLP.jpg',
          seo: {
            alt: 'Samsung Next Galaxy',
            desc: 'Samsung Next Galaxy',
          },
        },
        redirectUrl: '/',
        sortIndex: 3,
      },
      {
        image: {
          path: 'https://storage.googleapis.com/xooply-assets/recommendation-4_jiX-0Bj8q.jpg',
          seo: {
            alt: 'Souvenir & Merchandise',
            desc: 'Souvenir & Merchandise',
          },
        },
        redirectUrl: '/',
        sortIndex: 4,
      },
    ],
  },
  middle_banner: {
    name: 'middle_banner',
    title: 'middle_banner',
    content: {
      image: {
        path: 'https://storage.googleapis.com/xooply-assets/middle-banner_-b3SkyLva.jpg',
        seo: {
          alt: 'Xooply your business solution',
          desc: 'Xooply your business solution',
        },
      },
      redirectUrl: '/',
      sortIndex: 1,
    },
  },
  highlighted_products: [
    {
      name: 'Office Supplies',
      title: 'Office Supplies',
      content: {
        redirectUrl: null,
        banner: {
          image: {
            path: 'https://storage.googleapis.com/xooply-assets/highlighted-product-kantor.jpg',
            seo: {
              alt: 'Groceries',
              desc: 'Groceries',
            },
          },
          redirectUrl: '/',
          sortIndex: 2,
        },
        products: [],
      },
    },
    {
      name: 'Komputer & Elektronik',
      title: 'Komputer & Elektronik',
      content: {
        redirectUrl: '/',
        banner: {
          image: {
            path: 'https://storage.googleapis.com/xooply-assets/highlighted-product-gadget.jpg',
            seo: {
              alt: 'Komputer & Elektronik',
              desc: 'Komputer & Elektronik',
            },
          },
          redirectUrl: '/',
          sortIndex: 1,
        },
        products: [],
      },
    },
  ],
};

const data = CONFIG_SITE?.CONTENT?.PAGE?.MAINSITE?.HOMEPAGE ?? defaultData;

export default data;
